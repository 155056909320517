import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { ServerService } from 'src/app/service/server.service';

@Component({
  selector: 'app-categories-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.scss']
})
export class CategoriesListComponent implements OnInit {

  public dynamicContent: any[] = [];
  public featuredProducts: any[] = [];
  public  banner1: any[] = [];
  public  banner2: any[] = [];
  public  banner3: any[] = [];
  public  banner4: any[] = [];
  public baseUrl: string = 'category'

  constructor(
    private serverService: ServerService
  ) { }

  ngOnInit(): void {
    CommonService.fetchAll(this).then(()=>{
      this.dynamicContent = this.dynamicContent.filter(i=>i.level==1)
    })

    // this.serverService.popularProduct('product').subscribe((response) => {
    //   this.featuredProducts  = response.data.filter(i=>i.fa==1);
     
	  //  });

     this.serverService.getAll('slider').subscribe((response) => {
       this.banner1 = [];
       this.banner2 = [];
       this.banner3 = [];
       this.banner4 = [];
       for(const item of response.data){        
         if(item.ty==2){
           this.banner1.unshift(item);
         }else  if(item.ty==3){
           this.banner2.unshift(item);
         }else  if(item.ty==4){
           this.banner3.unshift(item);
         }else  if(item.ty==5){
           this.banner4.unshift(item);
         }
       }
	   });
    
  }

  ParseImage(imageObj){
    if(imageObj){
      let parsed = JSON.parse(imageObj)[0];
      if(parsed.default){
        return parsed.url;
      }
      return parsed.url+parsed.nm;
    } else{
      return;
    }
  }

  getUrl(text){
    return text.replace(/\s+/g, '-').toLowerCase();
  }

}
